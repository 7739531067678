.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper-terminos {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  top: 10px;
  width: 100%;
  height: 90%;
  background: white;
  border-radius: 10px;
  overflow: auto;
  
}
.modal-content-terminos {
  top: 10px;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 10px;
  overflow: auto;
  
}


.modal-header {
  position: fixed;
  top: 47px;  /* Ajusta el valor de acuerdo a la altura del header fijo existente */
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #1c1a1a;
  background: #070707;
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  font-size: 18;
}

.modal-header-terminos {
  position: fixed;
  top: 0px;  /* Ajusta el valor de acuerdo a la altura del header fijo existente */
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #1c1a1a;
  background: #070707;
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  font-size: 18;
}

.modal-body {
  font-size: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: black; /* Establecer el color del texto en negro */
  align-items: center;
  overflow-y: auto; /* Agregar scrolling vertical al contenido */
  margin-top: 100px; /* Ajustar el valor de acuerdo a la altura del header fijo */
}

.modal-body p {
  text-align: left; /* Justificar el texto hacia la izquierda */
  max-width: 50%; /* Establecer un ancho máximo del 50% */
  font-weight: 110;
  color: black; /* Establecer el color del texto en negro */
}



.modal-body-terminos {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Agregar scrolling vertical al contenido */
  margin-top: 100px; /* Ajustar el valor de acuerdo a la altura del header fijo */
}
.modal-body-terminos p {
  text-align: left; /* Justificar el texto hacia la derecha */
  max-width: 50%; /* Establecer un ancho máximo del 70% */
  font-size: 18px;
  font-weight: 110;
}
.question-button {
  background-color: #313C44 !important;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  top: 20px;
  right: 20px;
}
.close-button {
  position: absolute;
  top: 72px;
  right: 30px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 9999;
  color: rgb(255, 255, 255);
  font-size: 20px;
  opacity: 1; /* Asegura que el botón sea visible */
  pointer-events: auto; /* Permite que el botón sea reactivo a eventos de ratón */
}

.close-button-terminos {
  position: absolute;
  top: 18px;
  right: 30px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 9999;
  color: rgb(255, 255, 255);
  font-size: 20px;
  opacity: 1; /* Asegura que el botón sea visible */
  pointer-events: auto; /* Permite que el botón sea reactivo a eventos de ratón */
}

.button-link {
  display: inline-block;
  padding: 1px 1px;
  background-color: transparent;
  color: #000000;
  text-decoration: none;
  border-radius: 5px;
}

.button-link:hover {
  background-color: transparent;
  color: grey;;
  text-decoration: none; /* Asegurar que no haya subrayado al hacer hover */
}

.button-link:focus {
  outline: none;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .modal-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Agregar scrolling vertical al contenido */
  margin-top: 100px; /* Ajustar el valor de acuerdo a la altura del header fijo */
}
  .modal-body p {
      text-align: left; /* Justificar el texto hacia la derecha */
      max-width: 90%; /* Establecer un ancho máximo del 70% */
      font-weight: 110;
    }
    .modal-body-terminos p {
      text-align: left; /* Justificar el texto hacia la derecha */
      max-width: 90%; /* Establecer un ancho máximo del 70% */
      font-size: 18px;
      font-weight: 110;
    }

    .modal-header-terminos {
      position: fixed;
      top: 0px;  /* Ajusta el valor de acuerdo a la altura del header fijo existente */
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid #1c1a1a;
      background: #030303;
      color: rgb(255, 255, 255);
      text-align: left;
      font-size: 16px;
    }

    .modal-header {
      position: fixed;
      top: 47px;  /* Ajusta el valor de acuerdo a la altura del header fijo existente */
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid #1c1a1a;
      background: #030303;
      color: rgb(255, 255, 255);
      text-align: left;
      font-size: 16px;
      
    }

    .close-button {
      position: absolute;
      top: 67px;
      right: 30px;
      background: none;
      border: none;
      cursor: pointer;
      z-index: 9999;
      color: rgb(255, 255, 255);
      font-size: 20px;
      opacity: 1; /* Asegura que el botón sea visible */
      pointer-events: auto; /* Permite que el botón sea reactivo a eventos de ratón */
    }
}

/* Para pantallas más pequeñas */
@media screen and (max-width: 768px) {
  .modal-content {
    width: 90%; /* Ajusta el ancho del modal al 90% de la pantalla */
    height: auto; /* Permite que el modal crezca según el contenido */
    margin: 20px auto; /* Añade un poco de espacio alrededor del modal */
  }

  .modal-body {
    margin-top: 120px; /* Ajustar el margen superior */
    padding: 10px;
  }

  .modal-body p {
    max-width: 90%; /* Establecer un ancho máximo del 90% */
  }

  /* Ajustar el tamaño del video en pantallas pequeñas */
  .componente1 video {
    width: 100%; /* Escalar el video para que ocupe el ancho disponible */
    height: auto; /* Mantener la relación de aspecto del video */
  }

  .modal-header, .modal-header-terminos {
    font-size: 16px;
    text-align: left;
  }

  .close-button {
    top: 67px;
    right: 20px;
  }
}

/* Estilos generales */
.suscripcion-container {
  padding: 20px;
  font-family: 'Arial, sans-serif';
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}

.suscripcion-container h2, .suscripcion-container h3 {
  text-align: center;
}

.suscripcion-container ul {
  list-style-type: none;
  padding-left: 0;
}

.suscripcion-container li {
  margin-bottom: 15px;
}

.suscripcion-container strong {
  font-weight: bold;
}

/* Añadir margen a las líneas de separación */
.suscripcion-container hr {
  margin: 20px 0;
}

/* Estilos responsive para pantallas pequeñas */
@media screen and (max-width: 768px) {
  .suscripcion-container {
    padding: 10px;
    max-width: 100%; /* Ajustar el ancho al 100% para pantallas pequeñas */
  }

  .suscripcion-container h2 {
    font-size: 24px; /* Ajustar el tamaño del título */
  }

  .suscripcion-container h3 {
    font-size: 20px; /* Ajustar el tamaño de los subtítulos */
  }

  .suscripcion-container li {
    font-size: 16px; /* Ajustar el tamaño del texto para pantallas pequeñas */
  }

  .suscripcion-container ul {
    padding-left: 15px; /* Añadir un poco de espacio a la izquierda de las listas */
  }
}

/* Estilos para pantallas muy pequeñas (por debajo de 480px) */
@media screen and (max-width: 480px) {
  .suscripcion-container h2 {
    font-size: 20px;
  }

  .suscripcion-container h3 {
    font-size: 18px;
  }

  .suscripcion-container li {
    font-size: 14px;
  }
}
