.grid-container {
    display: flex;
    grid-template-rows: auto auto;
    grid-gap: 2px;
    justify-content: center; /* Alinea los items en el centro horizontalmente */
    align-items: center; /* Alinea los items en el centro verticalmente */
    height: 80vh; /* Establece el alto del contenedor al 100% de la altura visible de la ventana */
    overflow-y: auto;
  }
  

  .grid-item {
    width: 30%;
    padding: 2px;
    
  }
  
  .componente1 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
  

  }
  
  .componente2 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: none; /* Removemos el box-shadow original */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3) 0.2%, rgba(255, 69, 0, 0.3) 30%, rgba(237, 153, 96, 0.3) 69.8%);

  background-position: bottom;
  background-size: 100% 1px; /* Tamaño del borde degradado */
  background-repeat: no-repeat; /* Asegura que no se repita */

  }
  .componente3 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: none; /* Removemos el box-shadow original */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3) 0.2%, rgba(255, 69, 0, 0.3) 30%, rgba(237, 153, 96, 0.3) 69.8%);

  background-position: bottom;
  background-size: 100% 1px; /* Tamaño del borde degradado */
  background-repeat: no-repeat; /* Asegura que no se repita */

  }
  
  .componente4 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: none; /* Removemos el box-shadow original */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3) 0.2%, rgba(255, 69, 0, 0.3) 30%, rgba(237, 153, 96, 0.3) 69.8%);

  background-position: bottom;
  background-size: 100% 1px; /* Tamaño del borde degradado */
  background-repeat: no-repeat; /* Asegura que no se repita */

  }

  .close-button-terminos {
    position: absolute;
    top: -88px;
    right: 30px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 9999;
    color: rgb(255, 255, 255);
    font-size: 20px;
    opacity: 1; /* Asegura que el botón sea visible */
    pointer-events: auto; /* Permite que el botón sea reactivo a eventos de ratón */
  }

  .button-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: transparent;
    color: black;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .button-link:hover {
    background-color: transparent;
    color: grey;;
    text-decoration: none; /* Asegurar que no haya subrayado al hacer hover */
  }
  
  .button-link:focus {
    outline: none;
    text-decoration: none;
  }
  .dynamic-text {
    text-align: left; /* Alinea a la derecha por defecto (pantallas más grandes) */
  }
  
  @media (max-width: 768px) {
    .grid-container {
      flex-direction: column;
    }

  }

  @media (max-width: 768px) {
    .grid-item {
      width: 100%;
      padding: 1px;
    }
  
    .componente1 {
      display: none;
    }
    .dynamic-text {
      font-size: 4vw; /* Ajusta el tamaño del texto dinámicamente en móviles */
      white-space: nowrap; /* Mantiene el texto en una sola línea */
      overflow: hidden; /* Evita que el texto desborde */
      text-overflow: ellipsis; /* Agrega "..." si el texto es muy largo */
      text-align: center; /* Centra el texto en móviles */
      width: 100vw; /* Asegura que ocupe todo el ancho de la ventana */
      margin: 0; /* Elimina márgenes para ocupar todo el ancho */
      padding: 0; /* Elimina padding */
      background-color: transparent; /* Asegura que no haya color de fondo */
      
    }
    .streaming-text {
      text-align: left; /* Alinea el texto de StreamingText a la izquierda */
      font-size: initial; /* Mantén el tamaño de fuente original en móviles */
      border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    background-color: transparent; /* Asegura que no haya color de fondo */
    }
  
    .componente2, .componente3, .componente4 {
      background-color: #ffffff;
      padding: 20px;
      border-radius: 5px;
      box-shadow: none; /* Removemos el box-shadow original */
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3) 0.2%, rgba(255, 69, 0, 0.3) 30%, rgba(237, 153, 96, 0.3) 69.8%);
      background-position: bottom;
      background-size: 100% 1px; /* Tamaño del borde degradado */
      background-repeat: no-repeat; /* Asegura que no se repita */
    }
  
    .grid-container {
      display: flex;
      grid-template-rows: auto auto;
      grid-gap: 0px;
      justify-content: center; /* Alinea los items en el centro horizontalmente */
      align-items: flex-start; /* Cambiamos a flex-start para alinear los items arriba */
      padding-top: 0px; /* Agrega espacio en la parte superior si es necesario */
      height: 80vh; /* Mantiene el contenedor al 80% de la ventana */
      overflow-y: auto; /* Permite el desplazamiento vertical */
      scrollbar-width: none; /* Oculta la barra de desplazamiento en Firefox */
      -ms-overflow-style: none; /* Oculta la barra de desplazamiento en Internet Explorer */
    }
    
    .grid-container::-webkit-scrollbar {
      display: none; /* Oculta la barra de desplazamiento en Chrome, Safari y Edge */
    }
    
  }
  