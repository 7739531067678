.survey-container {
    text-align: center;
    padding: 20px;
}

.survey-step {
    font-size: 20px;
    margin-bottom: 20px;
}

.survey-steps {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.step-dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #beeaf8;
    border-radius: 50%;
    display: inline-block;
}

.step-dot.completed {
    background-color: #313C44;
}

.survey-content {
    margin-bottom: 20px;
}

.input-step {
    margin-bottom: 20px;
}

.input-step label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
}

.input-step input {
    padding: 8px;
    font-size: 16px;
    width: 100%;
}

.survey-groups {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    width: 700px; 
}

.survey-group {
    display: flex;
    flex-direction: column;
    border: 1px solid #beeaf8;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    width: 200px;
}


.survey-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.survey-header span {
    font-size: 14px;
}

.survey-item {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    text-align: left;
}

.question-text {
    flex: 1;
    padding-right: 10px;
    text-align: left;
    font-size: 14px;
}

.survey-inputs {
    display: flex;
    justify-content: space-around;
    width: 80px;
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 7px;
}

.input-group label {
    margin-bottom: 3px;
}

.input-group input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
}

.input-group input[type="radio"]:checked {
    border-color: #313C44;
}

.input-group input[type="radio"]:checked::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #313C44;
    position: absolute;
    top: 2px;
    left: 2px;
}

.survey-navigation {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.survey-navigation .navi-button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #313C44;
    color: white;
    border: none;
    border-radius: 5px;
}

.survey-navigation .navi-button:hover,
.survey-navigation .navi-button:focus {
    background-color: #beeaf8 !important;
    border: none;
    color: #313C44;
}

.navi-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.survey-scores {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.survey-scores p {
    margin: 0 10px;
}

