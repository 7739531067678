.modal-wrapper-detalles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999; /* Asegurarse de que el modal esté encima de otros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay-detalles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 9998; /* Fondo del modal, por debajo del contenido del modal */
}

.modal-content-detalles {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto; /* Agregar scrolling vertical al contenido */
}

.modal-header-detalles {
  top: 25px;
  padding: 15px;
  font-size: 15px;
  color: rgb(0, 0, 0); /* Color del texto a negro para mejor legibilidad */
}
.modal-header-detalles-close {
  position: absolute;
  top: 30px;
  right: 10px;
  
}

.modal-body-detalles {
  padding: 10px; /* Ajuste general de padding */
  padding-bottom: 20px; /* Ajuste específico para el padding inferior */
  color: rgb(0, 0, 0); /* Color del texto a negro para mejor legibilidad */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Agregar scrolling vertical al contenido */
  flex-grow: 1; /* Para que el cuerpo ocupe el espacio restante */
  max-height: 80vh; /* Ajustar la altura máxima al 80% de la altura de la ventana */
  z-index: 9999;
}

.detalles p {
  margin: 10px 0; /* Ajustar márgenes para mejor legibilidad */
  font-weight: 110;
  color: rgb(0, 0, 0); /* Color del texto a negro para mejor legibilidad */
}

.detalles h3 {
  margin: 10px 0; /* Ajustar márgenes para mejor legibilidad */
  font-weight: 110;
  color: rgb(0, 0, 0); /* Color del texto a negro para mejor legibilidad */
}

.detalles h4 {
  margin: 10px 0; /* Ajustar márgenes para mejor legibilidad */
  font-weight: 110;
  color: rgb(0, 0, 0); /* Color del texto a negro para mejor legibilidad */
}

.close-button-detalle {
  position: absolute;
  top: 25px;
  right: -15px;
  background: #000000; /* Fondo negro */
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: white; /* Icono blanco */
  border-radius: 50%; /* Opción para redondear el botón */
  width: 30px; /* Ajustar tamaño del botón */
  height: 30px; /* Ajustar tamaño del botón */
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width: 768px) {
  .modal-body-detalles {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Agregar scrolling vertical al contenido */
  margin-top: 100px; /* Ajustar el valor de acuerdo a la altura del header fijo */
}
 
    .modal-body-detalles p {
      text-align: left; /* Justificar el texto hacia la derecha */
      max-width: 90%; /* Establecer un ancho máximo del 70% */
      max-height: 90%;
      font-size: 18px;
      font-weight: 110;
      
    }

    .modal-header-detalles {
      position: fixed;
      top: 0px;  /* Ajusta el valor de acuerdo a la altura del header fijo existente */
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid #1c1a1a;
      background: #0d0c0c;
      color: rgb(255, 255, 255);
      text-align: left;
      font-size: 15px;
    }
}