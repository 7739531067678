/* Spinner.css */
.spinnerpuntitos {
    display: inline-block;
    font-size: 40px;
    font-weight: bold;
  }
  .spinnerpuntitos p {
    display: inline-block;
    font-size: 20px;
    font-weight: 110;
  }
  
  .dot {
    transition: opacity 0.7s ease-in-out;
  }
  