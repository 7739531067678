/* Chat.css */


body {
  overflow-x: hidden; /* Oculta la barra de desplazamiento horizontal en el cuerpo */
  overflow-y: hidden; /* Oculta la barra de desplazamiento vertical */
}

/* Barra de menú flotante superior */
.floating-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: none; /* Removemos el box-shadow original */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3) 0.2%, rgba(255, 69, 0, 0.3) 30%, rgba(237, 153, 96, 0.3) 69.8%);

  background-position: bottom;
  background-size: 100% 1px; /* Tamaño del borde degradado */
  background-repeat: no-repeat; /* Asegura que no se repita */
}


/* Estilos generales */
.chat-container {  
  position: relative;
  height: 80vh;
  overflow-y: auto; /* Oculta la barra de desplazamiento vertical */
 
}

.sidebar {
  position: fixed;
  top: 52px;
  left: -1;
  width: 200px;
  height: 100%;
  background-color: #f7f8fb;
  padding: 20px;
  overflow-y: hidden;
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  
}

.sidebarTrained {
  position: fixed;
  top: 52px;
  left: -1;
  width: 240px;
  height: 100%;
  background-color: #f7f8fb;
  padding: 20px;
  overflow-y: hidden;
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  
}

.sidebar.open {
  transform: translateX(0);
}
.sidebar.close {
  transform: translateX(-205px); /* Mueve el sidebar fuera de la pantalla hacia la izquierda */
}

.sidebarTrained.close {
  transform: translateX(-245px); /* Mueve el sidebar fuera de la pantalla hacia la izquierda */
}

.sidebar-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #7f8083;
}

.sidebar-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(4, 4, 4);
  bottom: 0;
}

.sidebar span {
  background: none;
  border: none;
  cursor: pointer;
  color: #141414;
  font-size: 12px;
  
}

.sidebarTrained span {
  background: none;
  border: none;
  cursor: pointer;
  color: #141414;
  font-size: 12px;
  
}

.messages {
  max-height: calc(97vh - 150px);
  overflow-y: auto;
  background-color: transparent;
}

.messages::-webkit-scrollbar {
  width: 0; /* Ancho de la barra de desplazamiento */
  background: transparent; /* Color de fondo de la barra, opcional */
}
.messages {
  scrollbar-width: none; /* Oculta la barra de desplazamiento */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.message {
  margin-bottom: 2px;
  padding: 8px 12px;
  border-radius: 5px;
  max-width: 90%;
  position: relative;  /* Asegura que z-index funcione */
  background-color: transparent;
  text-align: justify;
  text-underline-position: auto;
  font-size: 16px; /* Tamaño del texto */
  line-height: 1.7; /* Espacio entre líneas */
  color: #545557;
}


.user {
  align-self: flex-end;
  background: transparent;
  color: #000000;
  text-decoration: solid;
}
.chat-img-user {
  width: 31px; /* Tamaño de la imagen del perfil */
  height: 31px;
  border-radius: 2%; /* Para que la imagen tenga forma circular */
  background-color: transparent;
  padding-right: 8px;
}
.chat-img-bot {
  width: 38px; /* Tamaño de la imagen del perfil */
  height: 38px;
  border-radius: 2%; /* Para que la imagen tenga forma circular */
  background-color: transparent;
  padding-right: 8px;
}

.bot {
  align-self: flex-start;
  background-color: transparent;
  color: #272727;
}

/* Estilos para opciones de mensaje */
.message-options {
  position: absolute;
  top: 25px;
  right: -30px; /* Ajusta la posición de las opciones */
}


.pregunta-options-button {
  margin-left: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

.respuesta-options-button {
  margin-left: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

/* Estilos para el botón de descarga */
.message-options button.download {
  color: #007bff; /* Color del botón */
}

.message-options button.download:hover {
  color: #0056b3; /* Color del botón al hacer hover */
}

/* Input flotante en la parte inferior */
.main {
  position: fixed;
  bottom: 0;
  left: 50%; /* Establecer el punto de partida en el centro horizontal */
  transform: translateX(-50%); /* Desplazar el elemento a la izquierda en un 50% de su ancho */
  width: 50%;
  background-color: #fff;
  padding: 10px;
  
}

.mainTrained {
  position: fixed;
  bottom: 0;
  left: 50%; /* Establecer el punto de partida en el centro horizontal */
  transform: translateX(-50%); /* Desplazar el elemento a la izquierda en un 50% de su ancho */
  width: 50%;
  background-color: #fff;
  padding: 10px;
  
}
.main2 {
  position: fixed;
  top: 55px;
  left: 51%; /* Establecer el punto de partida en el centro horizontal */
  transform: translateX(-50%); /* Desplazar el elemento a la izquierda en un 50% de su ancho */
  width: 62%;
  background-color:transparent;
  padding: 10px;
  
}

.main input[type="text"] {
  width: calc(100% - 70px);
  padding: 8px;
  margin-right: 10px;
  position:'fixed';
}

.mainTrained input[type="text"] {
  width: calc(100% - 70px);
  padding: 8px;
  margin-right: 10px;
  position:'fixed';
}

.mainTrained button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.main button:hover {
  background-color: #5a5b5e;
}

.mainTrained button:hover {
  background-color: #5a5b5e;
}

/* Estilos para el botón flotante de scroll al fondo */
.scroll-to-bottom {
  position: fixed;
  top: 87%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centra el botón */
  background-color: #ffffff;
  color: #141414;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.scroll-to-bottom:hover {
  background-color: #545557;
  color: #ffffff;
}

.scroll-to-bottom svg {
  font-size: 19px;
}

/* Estilos para el dropdown */
.dropdown {
  position: fixed;
  top: 30px;
  right: 120px;
  background-color: transparent;
  z-index: 998;
  
}

.dropdown button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  flex-grow: 1; /* Ajusta el contenido del menú a la izquierda */
  z-index: 998;
  
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: -40px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  z-index: 998;
  border: 1px solid #e2e2e2;
}

.dropdown-menu button {
  display: flex; /* Cambiar de block a flex para alinear horizontalmente */
  /* width: auto; Asegurarse de que el botón no tenga ancho completo */
  padding: 8px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  align-items: center; /* Alinea verticalmente los elementos dentro del botón */
  font-size: 14px;
  white-space: nowrap;
  
}

.dropdown-menu div {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Alinea los elementos al inicio */
}

.dropdown-menu span {
  margin-right: 8px; /* Agrega un margen entre el texto "Salir" y el icono */
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}
.login-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: none;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  
}

.login-button:hover {
  background-color: #5a5b5e;
}


.input-container {
  display: flex;
}

.input-container input {
  flex: 1;
  padding: 18px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.input-container input {
  flex: 1;
  padding: 18px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.input-container input:focus {
  border-color: rgb(15, 15, 15); /* Cambia el color del borde a negro cuando se selecciona */
  outline: none; /* Elimina el contorno azul por defecto */
}


.input-container label {
  font-size: 15px;
  font-weight: 110;
}

.input-container input::placeholder {
  font-size: 14px;
  font-weight: 100;
  font-family: 'Arial', sans-serif; /* Cambiar la fuente a una más delgada */
  opacity: 0.7; /* Reducir la opacidad para que se vea más ligero */
}



.input-container form {
  flex: 1;
  padding: 18px;
  
  border-radius: 10px;
}

.input-container button {
  background-color: #ffffff;
  color: rgb(45, 45, 45);
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #545557;
  color: #ffffff;
}
.button-container {
  display: flex;
  justify-content: space-between; /* Espaciado uniforme entre los botones */
  margin-top: 3px; /* Margen superior para separar los botones del input */
}

.button-container button {
  background: none;
  border: none;
  cursor: pointer;
  color: #414142;
  
}

.button-container button p {
  font-size: 10px; /* Tamaño de letra pequeña */
  margin-top: 3px; /* Espacio entre el icono y el texto */
  color: #474747;
  font-weight: 110;
 
}

.disclaimer-base-container {
  font-size: 10px; /* Tamaño de letra pequeña */
  margin-top: 13px; /* Espacio entre el icono y el texto */
  margin-left: -6%; /* Espacio entre el icono y el texto */
  text-align: center;
  color: #1c1c1d;
  z-index: 9999;
}

.disclaimer-base-container-terminos {
  position: fixed;
  bottom: 0;
  font-size: 10px; /* Tamaño de letra pequeña */
  margin-bottom: 6px;
  margin-left: 46%; /* Espacio entre el icono y el texto */
  text-align: center;
  color: #1c1c1d;
  z-index: 9999;
}

.disclaimer-base-container-terminos a {
 
  font-size: 10px; /* Tamaño de letra pequeña */
  margin-top: 10px;
  text-align: center;
  z-index: 9999;
  background: linear-gradient(90deg, black 5%, #ff4500 30%, #ed9960 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.disclaimer-base-container-terminos-landing p{
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px; /* Tamaño de letra pequeña */
  margin-bottom: 6px;
  text-align: center;
  color: #1c1c1d;
  z-index: 9999;
}

.disclaimer-base-container-terminos-landing a{
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px; /* Tamaño de letra pequeña */
  margin-top: 6px;
  text-align: center;
  z-index: 9999;
  background: linear-gradient(90deg, black 5%, #ff4500 30%, #ed9960 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.disclaimer-base-container-suscripcion {
  position: fixed;
  bottom: 0;
  font-size: 16px; /* Tamaño de letra pequeña */
  margin-bottom: 6px;
  margin-left: 47%; /* Espacio entre el icono y el texto */
  text-align: center;
  color: #1c1c1d;
  z-index: 9999;
}

.button-container button svg {
  margin-right: 3px; /* Espacio entre el icono y el texto */
}

.sidebar-buttons button span {
  font-size: 12px; /* Tamaño de letra pequeña */
  margin-top: 3px; /* Espacio entre el icono y el texto */
}

.sidebar-content {
  position: fixed;
  top: 10%;
  
}

.sidebar-content p {
  color: #474747;
  font-weight: 150;
}

.profile-container {
  position: fixed;
  top: 13px;
  right: 90px;
  background-color: transparent;
  z-index: 999;
}
.sidebar-buttons {
  position: fixed;
  top: 10px;
  right: 35px;
}

.profile-container img {
  width: 30px; /* Tamaño de la imagen del perfil */
  height: 30px;
  border-radius: 50%; /* Para que la imagen tenga forma circular */
  background-color: transparent;
}



.profile-container h5 {
  margin-top: 5px; /* Espacio entre la imagen y el nombre */
  font-size: 14px; /* Tamaño del nombre del usuario */
  color: #7f8083; /* Color del nombre del usuario */
  background-color: transparent;
}

.floating-menu h4 {
  position: fixed;
  right: 47%;
  color: #414142;
}
.floating-menu-logo {
  position: fixed;
  width: 35px; /* Tamaño de la imagen del perfil */
  height: 35px;
  border-radius: 50%; /* Para que la imagen tenga forma circular */
  right: 55%;
  top: 6px;
  z-index: 997;
  padding-right: 10px;
}

.floating-menu-logo-2 {
  position: fixed;
  width: 155px; /* Tamaño de la imagen del perfil */
  height: 35px;
  left: 2%;
  top: 6px;
  z-index: 997;
  padding-right: 10px;
}


.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}


.contexto-container {
  display: block;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 60vh;
}

.contexto-container h2 {
  color: #333333; /* Gris oscuro */
  font-weight: 200;
}

.contexto-container p {
  color: #676565;; /* Gris claro */
  font-weight: 110;
}

.contexto-container button {
  background-color: #edecec; /* Negro */
  color: #ffffff; /* Letras blancas */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
}
.contexto-container button:hover {
  background-color: #ffffff; /* Gris claro al pasar el mouse */
  color: #000000; /* Letras blancas */
}

.contexto-container button .button-link {
  font-size: 12px; /* Tamaño de letra pequeña */
  margin-bottom: 6px;
  text-align: center;
  z-index: 9999;
  background: linear-gradient(90deg, black 5%, #ff4500 30%, #ed9960 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.buttonStyle {
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  padding: '8px 16px';
  border-radius: '4px';
  background-color: #007bff;
  color: #000205;
  border: 'none';
  cursor: 'pointer';
  font-size: '26px';
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner img {
  max-width: 100%;
}



@media (max-width: 768px){

  
  .contexto-container {
    display: block;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 40vh;
  }
  
.main {
  position: fixed;
  bottom: 0;
  left: 55%; /* Establecer el punto de partida en el centro horizontal */
  width: 90%;
  background-color: #fff;
  padding: 10px;
  
}

.mainTrained {
  position: fixed;
  bottom: 0;
  left: 55%; /* Establecer el punto de partida en el centro horizontal */
  width: 100%;
  background-color: #fff;
  padding: 10px;
  
}

.messages {
  max-height: calc(95vh - 150px);
  overflow-y: auto;
  background-color: transparent;
}


.main2 {
  position: fixed;
  top: 55px;
  left: 55%; /* Establecer el punto de partida en el centro horizontal */
  width: 87%;
  background-color:transparent;
  padding: 3px;
  bottom: auto;

}
.floating-menu-logo-2 {
  position: fixed;
  width: 152px; /* Tamaño de la imagen del perfil */
  height: 33px;
  left: 5%;
  top: 9px;
  z-index: 997;
  padding-right: 1px;
}
.profile-container {
  position: fixed;
  top: 13px;
  right: 30px;
  background-color: transparent;
  z-index: 999;
}
.dropdown {
  position: fixed;
  top: 30px;
  right: 53px;
  background-color: transparent;
  z-index: 998;
}

.dropdown-menu button {
  display: flex; /* Cambiar de block a flex para alinear horizontalmente */
  /* width: auto; Asegurarse de que el botón no tenga ancho completo */
  padding: 8px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  align-items: center; /* Alinea verticalmente los elementos dentro del botón */
  font-size: 14px;
  white-space: nowrap;
  
}

.message-options {
  position: absolute;
  top: 8px;
  right: -1px; /* Ajusta la posición de las opciones */
}
.pregunta-options-button {
 
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

.respuesta-options-button {
  
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

}

@media screen and (max-width: 768px) {
  .sidebar {
    transform: translateX(-205px);
  }
  .disclaimer-base-container-terminos {
    position: fixed;
    bottom: 0;
    font-size: 10px; /* Tamaño de letra pequeña */
    margin-left: 40%; /* Espacio entre el icono y el texto */
    text-align: center;
    color: #1c1c1d;
    z-index: 9999;
    
  }
  .disclaimer-base-container {
    font-size: 10px; /* Tamaño de letra pequeña */
   
    margin-right: 10%; /* Espacio entre el icono y el texto */
    color: #1c1c1d;
    z-index: 9999;
  }
}

/* Estilo base */
.responsive-container {
  text-align: center;
  padding: 20px;
}

.responsive-container h1 {
  font-size: 2rem; /* Ajusta el tamaño del texto según sea necesario */
  margin-bottom: 10px;
  font-weight: 110;
}

.responsive-container h5 {
  font-size: 1rem;
  color: #777777;
  font-weight: 110;
}

/* Estilo para dispositivos móviles */
@media screen and (max-width: 768px) {
  .responsive-container {
    padding: 3px;
  }

  .chat-container {  
    position: relative;
    height: 80vh;
    overflow-y: auto; /* Oculta la barra de desplazamiento vertical */
   
  }

  .responsive-container h1 {
    font-size: 1.1rem; /* Reducir el tamaño del texto en pantallas más pequeñas */
  }

  .responsive-container h5 {
    font-size: 0.875rem; /* Reducir el tamaño del texto en pantallas más pequeñas */
  }
}

/* Estilo para dispositivos móviles */
@media screen and (max-width: 768px) {
  .input-container input {
    font-size: 16px; /* Mantén un tamaño de fuente adecuado */
    padding: 1px;   /* Asegura que el padding no se agrande */
    border-radius: 3px;
    box-sizing: border-box; /* Asegura que el padding y el border se mantengan dentro del width */
  }

  .input-container button {
    padding: 10px;
  }
  

  @media only screen and (max-width: 600px) {
    .disclaimer-base-container-terminos-landing {
      font-size: 8px; /* Ajustar tamaño para dispositivos móviles */
    }
    
  }
}








.dynamic-text {
  text-align: right; /* Alinea a la derecha por defecto (pantallas más grandes) */
  font-weight: 120;
  box-sizing: border-box; /* Asegura que padding y bordes no afecten el ancho total */
}



