.posts-container {
  text-align: left;
  height: 40vh;
  max-height: 70%;
  overflow-y: auto;
  overflow-x: auto;
  font-size: 14px;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.post-item {
  position: relative;
  display: inline-block;
  padding: 4px;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.post-link {
  
  font-weight: 150;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.post-link.active {
  font-weight: 600;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pagination-controls {
  display: flex;
  justify-content: left;
  align-items: center;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pagination-controls button {
  border: none;
  background-color: #f7f8fb;
  padding-top: 15px;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pagination-controls span {
  border: none;
  background-color: #f7f8fb;
  padding-top: 14px;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.spinner img {
  width: 50px;
  height: 50px;
}

.dropdownH-menu button {
  padding: 8px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  text-align: left;
  z-index: 998;
  font-size: 14px;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dropdownH-menu button:hover {
  background-color: #f0f0f0;
}

.dropdownH-toggle {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 5px;
  transition: font-weight 0.3s ease;
  opacity: 0;
  pointer-events: none;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.post-item:hover .dropdownH-toggle {
  opacity: 1;
  pointer-events: auto;
}

.vertical-dots::before {
  content: '⋮';
  font-size: 24px;
  margin-left: 25px;
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dropdownH-toggle:hover .vertical-dots::before {
  font-weight: bold;
}

/* Nueva clase responsive-container con estilo de texto gradient */
.responsive-container {
  background: linear-gradient(75deg, rgb(27, 27, 27) 15%, #fb5c22 80%, #ed9960 5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px; /* Puedes ajustar el tamaño del texto aquí */
  padding: 10px; /* Puedes ajustar el padding según sea necesario */
  text-align: center; /* Puedes cambiar la alineación del texto */
}


  